var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('Logo'),_c('b-col',{staticClass:"slider-image d-none d-lg-flex align-items-center p-5",style:(_vm.randomSlide),attrs:{"lg":"8"}}),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Έλα στην ομάδα μας! ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Το όνομα σας*"}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","state":errors.length > 0 ? false:null,"name":"register-firstname","placeholder":"Όνομα"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","state":errors.length > 0 ? false:null,"name":"register-lastname","placeholder":"Επώνυμο"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Το τηλέφωνο σας*","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-mobile","state":errors.length > 0 ? false:null,"name":"register-mobile"},model:{value:(_vm.user.mobile),callback:function ($$v) {_vm.$set(_vm.user, "mobile", $$v)},expression:"user.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Το email σας*","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Ο κωδικός σας*"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Περιοχή ενδιαφέροντος*","label-for":"register-perioxh"}},[_c('validation-provider',{attrs:{"name":"perioxh","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-perioxh","state":errors.length > 0 ? false:null,"name":"register-perioxh"},model:{value:(_vm.user.perioxh),callback:function ($$v) {_vm.$set(_vm.user, "perioxh", $$v)},expression:"user.perioxh"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Το μήνυμα σας","label-for":"register-perioxh"}},[_c('b-form-textarea',{attrs:{"id":"register-message","name":"register-message"},model:{value:(_vm.user.message),callback:function ($$v) {_vm.$set(_vm.user, "message", $$v)},expression:"user.message"}})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Συμφωνώ με τους "),_c('a',{attrs:{"href":"https://www.myplaceup.com/oroi-proypotheseis-synergaton/","target":"_blank"}},[_vm._v("όρους & προϋποθέσεις")])])],1),_c('b-button',{attrs:{"disabled":!_vm.status,"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Εγγραφή ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Έχετε ήδη λογαριασμό;")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("  Συνδεθείτε εδώ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }