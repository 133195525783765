<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <Logo />

      <b-col
        :style="randomSlide"
        lg="8"
        class="slider-image d-none d-lg-flex align-items-center p-5"
      />

      <!-- Register -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Έλα στην ομάδα μας!
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- name -->
              <b-form-group label="Το όνομα σας*">
                <b-row>
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-firstname"
                        v-model="user.firstName"
                        :state="errors.length > 0 ? false:null"
                        name="register-firstname"
                        placeholder="Όνομα"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-lastname"
                        v-model="user.lastName"
                        :state="errors.length > 0 ? false:null"
                        name="register-lastname"
                        placeholder="Επώνυμο"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- phone -->
              <b-form-group
                label="Το τηλέφωνο σας*"
                label-for="register-mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required|phone"
                >
                  <b-form-input
                    id="register-mobile"
                    v-model="user.mobile"
                    :state="errors.length > 0 ? false:null"
                    name="register-mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Το email σας*"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Ο κωδικός σας*"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- perioxh -->
              <b-form-group
                label="Περιοχή ενδιαφέροντος*"
                label-for="register-perioxh"
              >
                <validation-provider
                  #default="{ errors }"
                  name="perioxh"
                  rules="required"
                >
                  <b-form-input
                    id="register-perioxh"
                    v-model="user.perioxh"
                    :state="errors.length > 0 ? false:null"
                    name="register-perioxh"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- message -->
              <b-form-group
                label="Το μήνυμα σας"
                label-for="register-perioxh"
              >
                <b-form-textarea
                  id="register-message"
                  v-model="user.message"
                  name="register-message"
                />
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox"
                >
                  Συμφωνώ με τους
                  <a href="https://www.myplaceup.com/oroi-proypotheseis-synergaton/" target="_blank">όρους & προϋποθέσεις</a>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                :disabled="!status"
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                Εγγραφή
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Έχετε ήδη λογαριασμό;</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp; Συνδεθείτε εδώ</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardTitle, BFormTextarea,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { formUtils } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Logo from '@/components/Logo.vue'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormTextarea,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [formUtils],
  data() {
    return {
      status: null,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        mobile: '',
        perioxh: '',
        message: '',
      },
      // validation
      required,
      email,
    }
  },
  computed: {
    randomSlide() {
      const imageNames = [1, 2, 3, 4]

      const number = imageNames[Math.floor(Math.random() * imageNames.length)]

      // eslint-disable-next-line import/no-dynamic-require, global-require
      const slide = require(`@/assets/images/auth/${number}.jpeg`)

      return {
        backgroundImage: `url(${slide})`,
      }
    },

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.register()
        }
      })
    },

    register() {
      axios.post('user-requests/submit', { ...this.user })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(axios.throwError)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style lang="scss" scoped>
.slider-image {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}
</style>
